import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

export const GalleryLightbox = ({
  setShowLightBox,
  mappedImages,
  currentIndex,
  setCurrentIndex,
}) => {
  const handleClose = () => {
    setShowLightBox(false);
  };

  const handleNext = () => {
    if (currentIndex < mappedImages.length - 1) {
      setCurrentIndex((p) => p + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex((p) => p - 1);
    }
  };

  return (
    <div className="fixed inset-0 z-50 grid h-full w-full grid-rows-[1fr_8fr] gap-x-2 bg-primary-900 md:grid-rows-[1fr_8fr]">
      <div className="row-span-1 row-start-1 grid w-full grid-cols-1 grid-rows-1 ">
        <div className="col-start-1 row-start-1 self-center justify-self-center text-white">{`${
          currentIndex + 1
        }/${mappedImages.length}`}</div>
        <i
          className="close fal fa-times col-span-1 col-start-1 row-start-1 mr-6 mt-6 cursor-pointer self-start justify-self-end text-xl text-white transition-all duration-300 ease-linear hover:text-primary-500"
          data-modal-close
          onClick={handleClose}
        />
      </div>
      <div className="h-full">
        <div className="col-start-1 row-start-2 mx-auto flex h-full w-full max-w-[1328px] items-center justify-between gap-x-3 px-3">
          <div onClick={handlePrevious} className="cursor-pointer">
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="group"
            >
              <rect
                width="48"
                height="48"
                rx="8"
                fill="#F0F6F7"
                className="fill-[#F0F6F7] transition-all duration-300 ease-linear group-hover:fill-[#117263]"
              />
              <path
                d="M28 16L20 24L28 32"
                stroke="#117263"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="stroke-[#117263] transition-all duration-300 ease-linear group-hover:stroke-[white]"
              />
            </svg>
          </div>
          <div className="flex w-4/5 max-w-[1032px] items-center justify-center md:w-full">
            <GatsbyImage
              image={mappedImages[currentIndex].childImageSharp.gatsbyImageData}
              className="h-full"
              imgClassName="object-contain"
            />
          </div>
          <div onClick={handleNext} className="cursor-pointer">
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="group"
            >
              <rect
                width="48"
                height="48"
                rx="8"
                transform="matrix(-1 0 0 1 48 0)"
                fill="#F0F6F7"
                className="fill-[#F0F6F7] transition-all duration-300 ease-linear group-hover:fill-[#117263]"
              />
              <path
                d="M20 16L28 24L20 32"
                stroke="#117263"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="stroke-[#117263] transition-all duration-300 ease-linear group-hover:stroke-[white]"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};
