import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import GraphQLErrorList from "../components/Blog/graphql-error-list";
import SearchEngineOptimization from "../components/SEO";
import GalleryContainer from "../components/Gallery/GalleryContainer";
import { StaticImage } from "gatsby-plugin-image";
import CTA from "../components/Repeating/CTA";
import { mapEdgesToNodes } from "../lib/helpers";
import GallerySection from "../components/Gallery/GallerySection";

function Gallery(props) {
  const { data, errors } = props;
  const mappedImages = mapEdgesToNodes(data.galleryImages);
  useEffect(() => {
    console.log(mappedImages);
  }, []);

  if (errors) {
    return (
      <Layout siteSettings={data.siteSettings} contactInfo={data.contactInfo}>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  return (
    <Layout siteSettings={data.siteSettings} contactInfo={data.contactInfo}>
      <SearchEngineOptimization
        title="RanchView Photo Gallery | RanchView Senior Assisted Living"
        description="Explore pictures of the comfortable and welcoming spaces at our beautiful assisted living home in Olivenhain. Then come visit us for an in-person tour."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <GallerySection
        sectionTitle="RanchView Photo Gallery"
        sectionDesc="Explore pictures of the comfortable and welcoming spaces at our beautiful assisted living home in Olivenhain. Then come visit us for an in-person tour."
      />
      <GalleryContainer mappedImages={mappedImages} />
      <CTA phonenumber={"(760) 753-5082"} />
    </Layout>
  );
}

export const query = graphql`
  {
    contactInfo: sanityContactinfo {
      title
      description
      phonenumber
      email
      address
      license
      bookingurl
    }
    siteSettings: sanitySiteSettings {
      title
      description
      author
      headerlogo {
        asset {
          gatsbyImageData
        }
      }
      footerlogo {
        asset {
          gatsbyImageData
        }
      }
      facebookurl
      twitterurl
      privacypolicy
    }
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Facebook.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Twitter.jpg" }
    ) {
      publicURL
    }
    galleryImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        absolutePath: { regex: "//8.0 Gallery//" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 900)
          }
        }
      }
    }
  }
`;

export default Gallery;
