import { GatsbyImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import { GalleryLightbox } from "./GalleryLightbox";

function GalleryContainer({ mappedImages }) {
  const [showLightBox, setShowLightBox] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const html = document.querySelector("html");
    if (html) {
      html.style.overflow = showLightBox ? "hidden" : "auto";
    }
  }, [showLightBox]);

  return (
    <div className="mx-auto mb-[120px] grid w-4/5 max-w-[1200px] grid-cols-1 gap-x-4 gap-y-4 md:grid-cols-2 lg:grid-cols-4">
      {mappedImages.map((image, index) => (
        <GalleryItem
          image={image.childImageSharp.gatsbyImageData}
          imageIndex={index}
          setCurrentIndex={setCurrentIndex}
          setShowLightBox={setShowLightBox}
        />
      ))}
      {showLightBox && (
        <GalleryLightbox
          setShowLightBox={setShowLightBox}
          mappedImages={mappedImages}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
        />
      )}
    </div>
  );
}

const GalleryItem = ({
  image,
  imageIndex,
  setCurrentIndex,
  setShowLightBox,
}) => {
  function handleClick() {
    setCurrentIndex(imageIndex);
    setShowLightBox(true);
  }
  return (
    <div onClick={handleClick} className="overflow-hidden rounded-[20px]">
      <GatsbyImage image={image} className="cursor-pointer" />
    </div>
  );
};

export default GalleryContainer;
